<script setup lang="ts">
const selectedFaq = ref(-1);

function toggleFaq(index: number) {
	selectedFaq.value = index === selectedFaq.value ? -1 : index;
}

const faqs = [
	{
		q: 'O que eu ganho ao usar o GameFique?',
		a: 'Acesso a uma plataforma completa de gamificação de vendas, pronta para engajar sua equipe, aumentar sua produtividade e melhorar os resultados. Inclui ferramentas intuitivas para monitoramento de metas, desafios personalizados e relatórios detalhados.',
	},
	{
		q: 'É fácil de configurar?',
		a: 'Sim! Em poucos passos você pode começar a usar a plataforma e aproveitar todos os recursos para transformar sua equipe de vendas.',
	},
	{
		q: 'Por que usar o GameFique em vez de criar minha própria solução?',
		a: 'Claro, você pode criar a sua. Mas quanto tempo e dinheiro vai gastar desenvolvendo algo do zero? Enquanto isso, com o GameFique, você já começa a engajar e aumentar os resultados enquanto seus concorrentes ainda estão pesquisando como fazer.',
	},
	{
		q: 'Tenho outras dúvidas!',
		a: 'Sem problemas! Entre em contato pelo e-mail: suporte@gamefique.com.br.',
	},
];
</script>

<template>
	<div>
		<section class="leading-relaxed max-w-screen-xl mt-12 mx-auto px-4 md:px-8">
			<div class="space-y-3 text-center">
				<h1 class="text-3xl font-semibold">Perguntas Frequentes</h1>
				<p class="max-w-lg mx-auto text-lg">Se sua dúvida não estiver aqui, entre em contato conosco!</p>
			</div>

			<div class="mt-14 max-w-2xl mx-auto">
				<div v-for="(item, index) in faqs" :key="index" class="space-y-3 mt-5 overflow-hidden border-b" @click="toggleFaq(index)">
					<h4 class="cursor-pointer pb-5 flex items-center justify-between text-lg font-medium">
						{{ item.q }}
						<svg
							v-if="selectedFaq === index"
							xmlns="http://www.w3.org/2000/svg"
							class="h-5 w-5 ml-2"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
						</svg>
						<svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
						</svg>
					</h4>
					<div v-show="selectedFaq === index">
						<div>
							<p class="pb-4">
								{{ item.a }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
