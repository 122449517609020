<script setup lang="ts">
const features = [
	{
		icon: 'i-tabler-shield-check',
		title: 'Autenticação Simplificada',
		desc: 'Facilite o acesso da sua equipe com login por senha ou redes sociais. Rápido, seguro e conveniente.',
	},
	{
		icon: 'i-tabler-credit-card',
		title: 'Gestão de Resultados',
		desc: 'Acompanhe métricas e desempenho da equipe em tempo real. Painel completo para monitorar e ajustar estratégias.',
	},
	{
		icon: 'i-tabler-database-star',
		title: 'Dados Sob Controle',
		desc: 'Armazenamento seguro e escalável com as melhores soluções de banco de dados. Suporte completo para integração com sua infraestrutura.',
	},
	{
		icon: 'i-tabler-users-group',
		title: 'Preparado para Crescer',
		desc: 'Crie equipes, defina metas e incentive sua força de vendas com desafios personalizados.',
	},
	{
		icon: 'i-tabler-palette',
		title: 'Design Elegante',
		desc: 'Experiência de uso impecável com interfaces modernas e intuitivas. Perfeito para todos os dispositivos.',
	},
	{
		icon: 'i-tabler-stars',
		title: 'Flexível e Personalizável',
		desc: 'Ajuste às necessidades do seu time com nossa plataforma adaptável e modular.',
	},
];
</script>

<template>
	<section class="py-14">
		<div class="max-w-screen-xl mx-auto px-4 text-center md:px-8">
			<div class="max-w-2xl mx-auto">
				<h3 class="text-3xl font-semibold sm:text-4xl">A maneira mais rápida de engajar sua equipe de vendas</h3>
				<p class="mt-3">Tudo pronto para você começar a usar e ver resultados agora mesmo!</p>
			</div>
			<div class="mt-12">
				<ul class="grid gap-y-8 gap-x-12 sm:grid-cols-2 lg:grid-cols-3">
					<li v-for="(item, index) in features" :key="index" class="space-y-3">
						<div class="w-12 h-12 mx-auto bg-primary/10 text-tertiary-600 rounded-full flex items-center justify-center">
							<UIcon :name="item.icon" class="h-6 w-6 text-tertiary-500" />
						</div>
						<h4 class="text-lg font-semibold">
							{{ item.title }}
						</h4>
						<p>
							{{ item.desc }}
						</p>
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>
