<script setup lang="ts">
const whatsappLink = useWhatsappContactLink();
</script>

<template>
	<section>
		<div class="max-w-screen-xl mx-auto px-4 py-28 gap-12 md:px-8">
			<div class="space-y-5 max-w-4xl mx-auto text-center">
				<h1 class="text-sm text-black dark:text-white font-medium">
					Transforme sua equipe em campeões com nossa plataforma de vendas gamificada.
				</h1>

				<h2 class="text-4xl font-extrabold mx-auto md:text-5xl">
					Aumente suas vendas

					<span class="text-transparent bg-clip-text bg-gradient-to-r from-[#e83b49] to-[#972c34]">Jogando</span>
				</h2>

				<p class="max-w-2xl mx-auto">Engaje, motive e conquiste resultados incríveis – tudo pronto para você usar agora! 🚀</p>

				<div class="items-center justify-center gap-x-3 space-y-3 sm:flex sm:space-y-0">
					<NuxtLink
						:href="whatsappLink"
						target="_blank"
						class="block py-2 px-4 text-black font-medium bg-primary-600 duration-150 hover:bg-primary-500 active:bg-primary-700 rounded-lg shadow-lg hover:shadow-none bg-tertiary-500"
					>
						Comece Agora
					</NuxtLink>

					<!--					<NuxtLink href="/login" class="block py-2 px-4 font-medium duration-150 active:bg-gray-100 border rounded-lg">Demo</NuxtLink>-->

					<!--					<div class="h-12 w-12 bg-primary-500 rounded-full" />-->
					<!--					<div class="h-12 w-12 bg-ui-500 rounded-full" />-->
					<!--					<div class="h-12 w-12 bg-main-500 rounded-full" />-->
					<!--					<div class="h-12 w-12 bg-secondary-500 rounded-full" />-->
					<!--					<div class="h-12 w-12 bg-tertiary-500 rounded-full" />-->
				</div>
			</div>

			<div class="mt-14">
				<img src="~/assets/images/hero.png" class="w-full shadow-lg rounded-lg border dark:invert" alt="" />
			</div>
		</div>
	</section>
</template>
