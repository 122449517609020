<script setup lang="ts">
const whatsappLink = useWhatsappContactLink();
</script>

<template>
	<NuxtLink
		:href="whatsappLink"
		external
		target="_blank"
		class="fixed bottom-4 right-4 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-all flex items-center justify-center w-14 h-14"
	>
		<Icon name="logos:whatsapp-icon" class="h-6 w-6" />
	</NuxtLink>
</template>
